import {Injectable} from '@angular/core';
import {ContractSummary} from '@api/model/contractSummary';
import {Query} from '@datorama/akita';
import {ActiveContractStore} from './active-contract.store';

@Injectable({providedIn: 'root'})
export class ActiveContractQuery extends Query<ContractSummary> {

  constructor(protected store: ActiveContractStore) {
    super(store);
  }

  getActiveContract(): ContractSummary {
    return this.getValue();
  }

}
