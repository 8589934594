import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-no-results-found',
  templateUrl: './no-results-found.component.html'
})
export class NoResultsFoundComponent {

  @Input() fullWidth = false; // default is false since most screens have a filter

  constructor() {
  }

}
