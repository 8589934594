import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@app/shared/auth.service';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authToken
      }
    });
    return next.handle(req).pipe(catchError(err => this.handleError(err)));

  }

  handleError(err: any) {
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 401:
          this.handleUnauthorized();
          return throwError({message: 'Session Expired. Please login again.'});
        default:
          break;
      }
    }

    return throwError(err);
  }

  /**
   * Cleans token from state and redirects to login page
   */
  handleUnauthorized() {
    this.router.navigate(['user/login']).then(() => this.authService.signOut());
  }
}
