import {Component} from '@angular/core';
import {ValueComponent} from '@forms/primitives/value/value.component';
import {camelCase, startCase} from 'lodash';

@Component({
  selector: 'app-formly-start-case-value',
  template: '<div>  {{ format(value) }} </div>',
})
export class StartCaseValueComponent extends ValueComponent {
  format(v) {
    return startCase(camelCase(v));
  }
}
