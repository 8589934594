import {Component} from '@angular/core';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {

  year = moment().year();

  constructor() {
  }

}
