/**
 * Zzzing Land Owner API
 * Zzzing Land Owner API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReservationPerformanceAverageStatistics { 
    /**
     * Average occupancy % of properties
     */
    average_occupancy?: number;
    /**
     * Average duration of a reservation (in nights)
     */
    average_reservation_length?: number;
    /**
     * Average duration between the booking date and the check in date (in days)
     */
    average_lead_time?: number;
    /**
     * Average price per night for the reserved bookings in the filtered period
     */
    average_night_price?: number;
}

