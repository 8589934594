import {Component, OnInit} from '@angular/core';
import {LandOwnerContractsService} from '@api/api/landOwnerContracts.service';
import {Contract} from '@api/model/contract';
import {ContractType} from '@api/model/contractType';
import {ActiveContractService} from '@app/shared/active-contract.service';
import {saveAs} from 'file-saver';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-contract-details-modal',
  templateUrl: './contract-details-modal.component.html'
})
export class ContractDetailsModalComponent implements OnInit {

  contract: Contract;

  constructor(private activeContractService: ActiveContractService,
              private contractsService: LandOwnerContractsService,
              private contractDetailsModal: BsModalRef) {
  }

  ngOnInit() {
    this.activeContractService.getActiveContract()
      .pipe(switchMap(activeContract => this.contractsService.getLandOwnerContractById(activeContract.id)))
      .subscribe(contract => this.contract = contract);
  }

  closeContractDetails(): void {
    this.contractDetailsModal.hide();
  }

  resolveBillingCycle() {
    switch (this.contract.contract_type) {
      case ContractType.ARBITRAGE:
        return this.contract.arbitrage_details.billing_cycle;
      case ContractType.COMMISSIONBASED:
        return this.contract.commission_details.billing_terms.billing_cycle;
      case ContractType.SERVICEMANAGEMENT:
        return this.contract.service_management_details.billing_terms.billing_cycle;
    }
  }

  resolveOwnerAvailableDaysFraction() {
    const rightsOfUseTerms = this.contract.service_management_details.right_of_use_terms;
    const availableDays = rightsOfUseTerms.maximum_allowed_days - rightsOfUseTerms.total_days_used;
    return `${availableDays} / ${rightsOfUseTerms.maximum_allowed_days}`;
  }

  resolveOwnerAvailableDaysPercentage() {
    const rightsOfUseTerms = this.contract.service_management_details.right_of_use_terms;

    if (rightsOfUseTerms.maximum_allowed_days === 0) {
      return 0;
    }

    const availableDays = rightsOfUseTerms.maximum_allowed_days - rightsOfUseTerms.total_days_used;
    return (availableDays / rightsOfUseTerms.maximum_allowed_days) * 100;
  }

  downloadContractDocument() {
    this.contractsService.getLandOwnerContractDocument(this.contract.id)
      .subscribe((response) =>
        saveAs(new Blob([response], {type: 'application/octet-stream'}), this.contract.document_name)
      );
  }
}
