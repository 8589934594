import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {AuthService} from '@app/shared/auth.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuthentication();
  }

  handleLoggedOutRedirect(isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigateByUrl('user/login').then(() => this.authService.signOut());
    }
  }

  checkAuthentication() {
    return this.authService.isLoggedIn().pipe(
      tap((isLoggedIn: boolean) => this.handleLoggedOutRedirect(isLoggedIn))
    );
  }
}
