import {Component, ViewChild} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import * as moment from 'moment';
import {BsDatepickerDirective} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'formly-date-type',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [],
})
export class DatepickerComponent extends FieldType {

  @ViewChild(BsDatepickerDirective, {static: false}) datepicker: BsDatepickerDirective;

  onValueChange() {
    if (this.datepicker) {
      const date = this.formControl.value as Date;
      if (date) {
        // By default, the date picked would be at 00:00 but in the user's locale (e.g. CET).
        // The actual value we set in the form will be in UTC
        const dateValueWithoutZone = moment(date).utc(true).toDate();
        this.formControl.setValue(dateValueWithoutZone, {emitModelToViewChange: false});
      }
    }
  }
}
