import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BoxesLoadingComponent} from '@app/components/boxes-loading/boxes-loading.component';
import {ColoredNumberDirective} from '@app/components/colored-number/colored-number.directive';
import {NoResultsFoundComponent} from '@app/components/no-results-found/no-results-found.component';
import {PipesModule} from '@app/pipes/pipes.module';
import {TranslateModule} from '@ngx-translate/core';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ErrorComponent} from '../views/error/error.component';

@NgModule({
  declarations: [ErrorComponent, NoResultsFoundComponent, ColoredNumberDirective, BoxesLoadingComponent],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule,
    PipesModule
  ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    ErrorComponent,
    TranslateModule,
    CommonModule,
    PipesModule,
    NoResultsFoundComponent,
    ColoredNumberDirective,
    BoxesLoadingComponent
  ]
})
export class SharedModule {
}
