import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '@env/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, OnDestroy {

  appRoot = environment.appRoot;

  constructor() {
  }

  ngOnInit() {
    document.body.classList.add('background');
  }

  ngOnDestroy() {
    document.body.classList.remove('background');
  }

}
