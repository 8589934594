<div class="modal-body text-center">
  <h6 class="modal-title mb-4">My Contract Details</h6>

  <div *ngIf="contract; else loading" class="px-md-4 px-sm-2 px-xs-0">

    <div class="row d-flex flex-row text-left justify-content-around mb-2">
      <div class="col-4">
        <p class="text-muted text-small mb-1">Contract Reference</p>
        <p class="text-muted text-one font-weight-semibold">{{contract.friendly_reference}}</p>
      </div>
      <div class="col-4">
        <p class="text-muted text-small mb-1">State</p>
        <p class="text-one">{{contract.state | enumeration}}</p>
      </div>
    </div>

    <div class="row d-flex flex-row text-left justify-content-around mb-2">
      <div class="col-4">
        <p class="text-muted text-small mb-1">Start Date</p>
        <p class="text-one">{{contract.start_date}}</p>
      </div>
      <div class="col-4">
        <p class="text-muted text-small mb-1">End Date</p>
        <p class="text-one">{{contract.end_date}}</p>
      </div>
    </div>

    <div class="row d-flex flex-row text-left justify-content-around mb-2">
      <div class="col-4">
        <p class="text-muted text-small mb-1">Auto Renew</p>
        <p class="text-one">
          <i class="fas" [ngClass]="{'fa-check text-success': contract.auto_renewal,
            'fa-times text-danger': !contract.auto_renewal}"></i>
        </p>
      </div>
      <div class="col-4">
        <p class="text-muted text-small mb-1">Billing Cycle</p>
        <p class="text-one">{{resolveBillingCycle() | enumeration}}</p>
      </div>
    </div>

    <div class="row d-flex flex-row text-left justify-content-around mb-0">
      <div class="col-4">
        <p class="text-muted text-small mb-1">Document</p>
        <p class="text-one truncate">
          <a *ngIf="contract.document_name" (click)="downloadContractDocument()" class="font-weight-semibold">
            {{contract.document_name}}
          </a>
          <span *ngIf="!contract.document_name">N/A</span>
        </p>
      </div>
      <div class="col-4">
        <ng-container *ngIf="contract.service_management_details">
          <p class="text-muted text-small mb-2">
            Available Days
            <i class="far fa-question-circle text-primary"
              tooltip="The number of days that may be blocked for personal use"
              placement="right"
              containerClass="text-small">
            </i>
          </p>
          <div class="position-relative progress-bar-circle-medium">
            <div class="text-muted text-one semi-progressbar-text">
              {{resolveOwnerAvailableDaysFraction()}}
            </div>
            <round-progress
              [current]="resolveOwnerAvailableDaysPercentage()"
              [max]="100"
              [responsive]="true"
              [duration]="1000"
              [animation]="'easeInOutQuart'"
              [semicircle]="true"
              [stroke]="20">
            </round-progress>
          </div>
        </ng-container>
      </div>
    </div>
    <button type="button" class="btn btn-outline-primary" (click)="closeContractDetails()">Close</button>
  </div>
</div>

<ng-template #loading>
  <div class="inline-loading"></div>
</ng-template>
