import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {get} from 'lodash';

@Component({
  selector: 'app-value',
  template: '<div style="user-select: all">  {{ format(value) }} </div>',
})
export class ValueComponent extends FieldType {
  constructor() {
    super();
  }

  get value() {
    return get(this.model, this.key);
  }

  format(v) {
    return v;
  }

}
