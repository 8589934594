/**
 * Zzzing Land Owner API
 * Zzzing Land Owner API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of the property
 */
export type PropertyType = 'APARTMENT' | 'BED_AND_BREAKFAST' | 'CHALET' | 'GUEST_HOUSE' | 'HOTEL' | 'LODGE' | 'RESORT' | 'VILLA' | 'CASTLE' | 'APARTHOTEL' | 'BOAT' | 'COTTAGE' | 'CAMPING' | 'HOUSE';

export const PropertyType = {
    APARTMENT: 'APARTMENT' as PropertyType,
    BEDANDBREAKFAST: 'BED_AND_BREAKFAST' as PropertyType,
    CHALET: 'CHALET' as PropertyType,
    GUESTHOUSE: 'GUEST_HOUSE' as PropertyType,
    HOTEL: 'HOTEL' as PropertyType,
    LODGE: 'LODGE' as PropertyType,
    RESORT: 'RESORT' as PropertyType,
    VILLA: 'VILLA' as PropertyType,
    CASTLE: 'CASTLE' as PropertyType,
    APARTHOTEL: 'APARTHOTEL' as PropertyType,
    BOAT: 'BOAT' as PropertyType,
    COTTAGE: 'COTTAGE' as PropertyType,
    CAMPING: 'CAMPING' as PropertyType,
    HOUSE: 'HOUSE' as PropertyType
};

