import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {LandOwnerChangePasswordParams} from '@api/model/landOwnerChangePasswordParams';
import {ChangePasswordModalForm} from '@app/containers/layout/topnav/change-password/change-password-modal.form';
import {AuthService} from '@app/shared/auth.service';
import {FormlyFormOptions} from '@ngx-formly/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html'
})
export class ChangePasswordModalComponent implements OnInit {

  list: any[];
  actionCompleted: () => void;

  model: LandOwnerChangePasswordParams = {old_password: '', new_password: ''};

  form = new FormGroup({});
  options: FormlyFormOptions = {};
  fields = ChangePasswordModalForm();

  constructor(
    private service: AuthService,
    private modalRef: BsModalRef
  ) {
  }

  ngOnInit(): void {
    this.actionCompleted = this.list[0] || (() => null);
  }

  submit() {
    if (this.form.valid) {
      this.service.changePassword(this.model).subscribe(() => {
        this.actionCompleted();
        this.modalRef.hide();
      }, error => {
        this.modalRef.hide();
        throw error;
      });
    }
  }

  cancel() {
    this.modalRef.hide();
  }

}
