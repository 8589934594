import {environment} from 'src/environments/environment';

const appRoot = environment.appRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  {
    icon: 'iconsminds-dashboard',
    label: 'menu.dashboard.root',
    to: `${appRoot}/dashboard`
  },
  {
    icon: 'iconsminds-home',
    label: 'menu.properties.root',
    to: `${appRoot}/properties`
  },
  {
    icon: 'iconsminds-calendar-4',
    label: 'menu.reservations.root',
    to: `${appRoot}/reservations`
  },
  {
    icon: 'iconsminds-euro',
    label: 'menu.statement.root',
    to: `${appRoot}/statement`
  }
];
export default data;
