export const ChangePasswordModalForm = () => [
  {
    className: 'col',
    wrappers: ['card'],
    validators: {
      validation: [
        {name: 'fieldMatch', options: {errorPath: 'new_password'}},
        {name: 'fieldMatch', options: {errorPath: 'new_password_retype'}},
      ]
    },
    fieldGroup: [
      {
        type: 'string',
        templateOptions: {
          label: 'Old Password',
          type: 'password',
          addonLeft: {
            icon: 'lock',
          },
          required: true,
        },
        key: 'old_password'
      },
      {
        type: 'string',
        templateOptions: {
          label: 'New Password',
          type: 'password',
          addonLeft: {
            icon: 'lock',
          },
          required: true,
          placeholder: 'Please enter new password',
        },
        key: 'new_password'

      },
      {
        type: 'string',
        templateOptions: {
          label: 'Repeat New Password',
          type: 'password',
          addonLeft: {
            icon: 'lock',
          },
          required: true,
          placeholder: 'Please re-enter new password',
        },
        key: 'new_password_retype'

      }
    ]
  }
];
