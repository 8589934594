// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dev.zzzing.com/api/landowner',
  appRoot: '/app',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 2500, // this is set to a high width so the sub menu will only stick on extremely large screens
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.zzzing',
  isDarkSwitchActive: false,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
};
