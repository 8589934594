import {Injectable} from '@angular/core';
import {LandOwnerContractsService} from '@api/api/landOwnerContracts.service';
import {ContractFilter} from '@api/model/contractFilter';
import {ContractState} from '@api/model/contractState';
import {ContractSummary} from '@api/model/contractSummary';
import {ContractType} from '@api/model/contractType';
import {ActiveContractQuery} from '@app/shared/active-contract/active-contract.query';
import {ActiveContractStore} from '@app/shared/active-contract/active-contract.store';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {first} from 'lodash';

@Injectable({providedIn: 'root'})
export class ActiveContractService {

  constructor(private contractsService: LandOwnerContractsService,
              private activeContractStore: ActiveContractStore,
              private activeContractQuery: ActiveContractQuery) {
  }

  getActiveContract(): Observable<ContractSummary> {
    const activeContract = this.activeContractQuery.getActiveContract();
    return activeContract?.id ? of(activeContract) : this.fetchAndStoreActiveContract();
  }

  fetchAndStoreActiveContract(): Observable<ContractSummary> {
    return this.getContracts()
      .pipe(
        map(contracts => first(contracts)),
        tap(contract => this.storeActiveContract(contract))
      );
  }

  getContracts(): Observable<ContractSummary[]> {
    return this.contractsService.getLandOwnerContracts(this.getActiveContractFilter());
  }

  storeActiveContract(contract: ContractSummary): void {
    console.log(`Active Contract: ${contract.friendly_reference}`);
    this.activeContractStore.storeActiveContract(contract);
  }

  removeActiveContract() {
    this.activeContractStore.removeActiveContract();
  }

  getActiveContractFilter(): ContractFilter {
    return {page_number: 0, page_size: 10, states: [ContractState.ACTIVE, ContractState.NEW], type: ContractType.SERVICEMANAGEMENT};
  }
}
