import {Injectable} from '@angular/core';
import {ContractSummary} from '@api/model/contractSummary';
import {Store, StoreConfig} from '@datorama/akita';

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'ActiveContract'})
export class ActiveContractStore extends Store<ContractSummary> {

  constructor() {
    super({});
  }

  storeActiveContract(contract: ContractSummary) {
    this.update(contract);
  }

  removeActiveContract() {
    this.update({});
  }
}

