/**
 * Zzzing Land Owner API
 * Zzzing Land Owner API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PeriodicPerformanceFilter { 
    /**
     * The start date of the period from which the periodic performance will be extracted
     */
    date_from: string;
    /**
     * The end date of the period from which the periodic performance will be extracted
     */
    date_to: string;
    contract_id: string;
    /**
     * The performance will only be evaluated for the given set of properties linked to the specified contract. If no properties are provided, it is assumed that the performance is extracted for all contract properties. 
     */
    property_ids?: Array<string>;
}

