/**
 * Zzzing Land Owner API
 * Zzzing Land Owner API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SystemTaxType = 'VAT' | 'NON_TAXABLE';

export const SystemTaxType = {
    VAT: 'VAT' as SystemTaxType,
    NONTAXABLE: 'NON_TAXABLE' as SystemTaxType
};

