export * from './landOwnerAuth.service';
import { LandOwnerAuthService } from './landOwnerAuth.service';
export * from './landOwnerCommercialDocuments.service';
import { LandOwnerCommercialDocumentsService } from './landOwnerCommercialDocuments.service';
export * from './landOwnerContracts.service';
import { LandOwnerContractsService } from './landOwnerContracts.service';
export * from './landOwnerDetails.service';
import { LandOwnerDetailsService } from './landOwnerDetails.service';
export * from './landOwnerPerformance.service';
import { LandOwnerPerformanceService } from './landOwnerPerformance.service';
export * from './landOwnerProperty.service';
import { LandOwnerPropertyService } from './landOwnerProperty.service';
export * from './landOwnerReservations.service';
import { LandOwnerReservationsService } from './landOwnerReservations.service';
export * from './landOwnerSalesChannels.service';
import { LandOwnerSalesChannelsService } from './landOwnerSalesChannels.service';
export * from './landOwnerUsers.service';
import { LandOwnerUsersService } from './landOwnerUsers.service';
export const APIS = [LandOwnerAuthService, LandOwnerCommercialDocumentsService, LandOwnerContractsService, LandOwnerDetailsService, LandOwnerPerformanceService, LandOwnerPropertyService, LandOwnerReservationsService, LandOwnerSalesChannelsService, LandOwnerUsersService];
