import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {GlideThumbsComponent} from './glide-thumbs/glide-thumbs.component';
import {GlideComponent} from './glide/glide.component';

@NgModule({
  declarations: [
    GlideComponent,
    GlideThumbsComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [],
  exports: [
    GlideComponent,
    GlideThumbsComponent
  ]
})

export class ComponentsCarouselModule {
}
