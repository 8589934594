import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@app/shared/auth/auth.guard';
import {ErrorComponent} from '@app/views/error/error.component';
import {environment} from 'src/environments/environment';

const appRoot = environment.appRoot.substr(1); // path cannot start with a slash

const routes: Routes = [
  {
    path: '',
    redirectTo: appRoot,
    pathMatch: 'full',
  },
  {
    path: appRoot, loadChildren: () => import('./app/app.module').then(m => m.AppModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {path: 'error', component: ErrorComponent},
  {path: '**', redirectTo: '/error'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ViewRoutingModule {
}
