import {HashLocationStrategy, LocationStrategy} from '@angular/common';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApiConfigFactory} from '@app/api.config.factory';
import {AppErrorHandler} from '@app/app.errorhandler';
import {BootstrapModule} from '@app/components/bootstrap/bootstrap.module';
import {AuthInterceptor} from '@app/shared/auth/auth.interceptor';
import {akitaConfig, persistState} from '@datorama/akita';
import {TranslateModule} from '@ngx-translate/core';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {ApiModule} from '../gen/api';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {LayoutContainersModule} from './containers/layout/layout.containers.module';
import {ViewsModule} from './views/views.module';

akitaConfig({ resettable: true }); // allows all Akita stores to be reset through 'resetStores'

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ApiModule.forRoot(ApiConfigFactory),
    SimpleNotificationsModule.forRoot({timeOut: 5000, preventDuplicates: true}),
    BootstrapModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: ErrorHandler, useClass: AppErrorHandler},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {provide: 'persistStorage', useValue: persistState()}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
