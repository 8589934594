import {Injectable} from '@angular/core';
import {LandOwnerAuthService} from '@api/api/landOwnerAuth.service';
import {LandOwnerChangePasswordParams} from '@api/model/landOwnerChangePasswordParams';
import {LoginSuccessResult} from '@api/model/loginSuccessResult';
import {ZzzingLandOwnerUser} from '@api/model/zzzingLandOwnerUser';
import {ActiveContractService} from '@app/shared/active-contract.service';
import {SessionQuery} from '@app/shared/auth/session/session.query';
import {SessionStore} from '@app/shared/auth/session/session.store';
import {resetStores} from '@datorama/akita';
import {Observable} from 'rxjs';
import {delayWhen, tap} from 'rxjs/operators';

export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

@Injectable({providedIn: 'root'})
export class AuthService {
  constructor(
    private sessionStore: SessionStore,
    private query: SessionQuery,
    private client: LandOwnerAuthService,
    private activeContractService: ActiveContractService) {
  }

  async signIn(credentials: ISignInCredentials): Promise<LoginSuccessResult> {
    this.clearAllStorage();
    return this.client.loginLandOwner({username: credentials.email, password: credentials.password})
      .pipe(tap(session => {
        this.sessionStore.login(session);
        return session;
      }), delayWhen(() => {
        // delayWhen is not really deprecated! (see https://stackoverflow.com/a/52639584)
        return this.activeContractService.fetchAndStoreActiveContract();
      }))
      .toPromise();
  }

  signOut() {
    this.activeContractService.removeActiveContract();
    this.sessionStore.logout();
    this.clearAllStorage();
  }

  sendPasswordEmail(email: string): Promise<any> {
    return this.client.forgotPasswordLandOwner({username: email}).toPromise();
  }

  changePassword(data: LandOwnerChangePasswordParams) {
    const {old_password, new_password} = data;
    return this.client.changePasswordLandOwner({old_password, new_password});
  }

  getUser(): Observable<ZzzingLandOwnerUser> {
    return this.query.user$;
  }

  isLoggedIn(): Observable<boolean> {
    return this.query.isLoggedIn$;
  }

  getToken(): string {
    return this.query.getToken();
  }

  clearAllStorage() {
    resetStores();
  }
}
