/**
 * Zzzing Land Owner API
 * Zzzing Land Owner API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The field to sort the filtered reservations by
 */
export type ReservationSortField = 'ID' | 'CHECK_IN_DATE' | 'LAST_UPDATED';

export const ReservationSortField = {
    ID: 'ID' as ReservationSortField,
    CHECKINDATE: 'CHECK_IN_DATE' as ReservationSortField,
    LASTUPDATED: 'LAST_UPDATED' as ReservationSortField
};

