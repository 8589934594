import {ErrorHandler, Inject, Injectable, Injector} from '@angular/core';
import {NotificationsService} from 'angular2-notifications';
import {startCase} from 'lodash';

@Injectable()
export class AppErrorHandler extends ErrorHandler {

  constructor(@Inject(Injector) private injector: Injector) {
    super();
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get notificationsService(): NotificationsService {
    return this.injector.get(NotificationsService);
  }

  public handleError(e: any): void {
    if (e.error?.subErrors) {
      for (const error of e.error.subErrors) {

        this.notificationsService.error(startCase(`${error.field}`),
          error.message,
          {
            theClass: 'primary',
            showProgressBar: false
          });
      }

    } else {
      this.notificationsService.error(
        'Error',
        e.error?.message || e.message,
        {
          theClass: 'outline primary',
          showProgressBar: false
        }
      );
    }


    super.handleError(e);
  }
}
