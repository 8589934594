import {Component} from '@angular/core';
import {ValueComponent} from '@forms/primitives/value/value.component';
import {camelCase, upperCase} from 'lodash';

@Component({
  selector: 'app-formly-upper-case-value',
  template: '<div>  {{ format(value) }} </div>',
})
export class UpperCaseValueComponent extends ValueComponent {
  format(v) {
    return upperCase(camelCase(v));
  }
}
