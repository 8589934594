import {Component, ViewChild} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'formly-textarea-type',
  templateUrl: './text-area.component.html'
})
export class TextAreaComponent extends FieldType {
  @ViewChild('#formTextArea') formFieldControl: AbstractControl;

  defaultOptions = {
    defaultValue: '',
  };
}

