/**
 * Zzzing Land Owner API
 * Zzzing Land Owner API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { KeyValuePair } from './keyValuePair';
import { PersonTitle } from './personTitle';


export interface LandOwnerContactDetails { 
    title?: PersonTitle;
    name?: string;
    surname?: string;
    email?: string;
    telephone_number?: string;
    mobile_number?: string;
    contact_address?: Address;
    preferred_contact_mode?: LandOwnerContactDetails.PreferredContactModeEnum;
    other_contact_options?: Array<KeyValuePair>;
}
export namespace LandOwnerContactDetails {
    export type PreferredContactModeEnum = 'EMAIL' | 'WHATSAPP' | 'FACEBOOK' | 'MOBILE' | 'TELEPHONE';
    export const PreferredContactModeEnum = {
        EMAIL: 'EMAIL' as PreferredContactModeEnum,
        WHATSAPP: 'WHATSAPP' as PreferredContactModeEnum,
        FACEBOOK: 'FACEBOOK' as PreferredContactModeEnum,
        MOBILE: 'MOBILE' as PreferredContactModeEnum,
        TELEPHONE: 'TELEPHONE' as PreferredContactModeEnum
    };
}


