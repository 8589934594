import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ChangePasswordModalComponent} from '@app/containers/layout/topnav/change-password/change-password-modal.component';
import {ContractDetailsModalComponent} from '@app/containers/layout/topnav/contract-details-modal/contract-details-modal.component';
import {PipesModule} from '@app/pipes/pipes.module';
import {FormsModule} from '@forms/forms.module';
import {TranslateModule} from '@ngx-translate/core';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ApplicationMenuComponent} from './application-menu/application-menu.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {ColorSwitcherComponent} from './color-switcher/color-switcher.component';
import {FooterComponent} from './footer/footer.component';
import {HeadingComponent} from './heading/heading.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {TopnavComponent} from './topnav/topnav.component';

@NgModule({
  declarations: [
    TopnavComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ColorSwitcherComponent,
    FooterComponent,
    HeadingComponent,
    ApplicationMenuComponent,
    ChangePasswordModalComponent,
    ContractDetailsModalComponent
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    TranslateModule,
    RouterModule,
    CollapseModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    PipesModule,
    RoundProgressModule,
  ],
  exports: [
    TopnavComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ColorSwitcherComponent,
    FooterComponent,
    HeadingComponent,
    ApplicationMenuComponent
  ]
})
export class LayoutContainersModule {
}
