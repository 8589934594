import {Injectable} from '@angular/core';
import {LoginSuccessResult} from '@api/model/loginSuccessResult';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface SessionState extends EntityState<LoginSuccessResult> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'Session'})
export class SessionStore extends EntityStore<SessionState> {

  constructor() {
    super({});
  }

  login(session: SessionState) {
    this.update(session);
  }

  logout() {
    this.update({});
  }
}

