<label>{{to.label}}</label>
<ng-select class="formly-select" [items]="options$ | async"
  [ngStyle]="to.style"
  [appendTo]="'body'"
  [placeholder]="to.label"
  [typeahead]="search$"
  [formControl]="formControl"
  [multiple]="to.multiple"
  [bindValue]="to.valueProp"
  [bindLabel]="to.labelProp"
  [hideSelected]="to.hideSelected"
  (scrollToEnd)="onScrollToEnd()">
  <ng-template ng-label-tmp let-item="item">
    <span>{{ getLabel(item) }}</span>
  </ng-template>
</ng-select>
