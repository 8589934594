import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule as AngularFormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ValueComponent} from '@app/forms/primitives/value/value.component';
import {SharedModule} from '@app/shared/shared.module';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {DatepickerComponent} from '@forms/primitives/datepicker/datepicker.component';
import {SliderFormComponent} from '@forms/primitives/slider/slider-form.component';
import {TickComponent} from '@forms/primitives/tick/tick.component';
import {IconValueComponent} from '@forms/primitives/value/icon-value.component';
import {LabelledLinkValueComponent} from '@forms/primitives/value/labelled-link-value.component';
import {LinkValueComponent} from '@forms/primitives/value/link-value.component';
import {MappedValueComponent} from '@forms/primitives/value/mapped-value.component';
import {MoneyValueComponent} from '@forms/primitives/value/money-value.component';
import {PercentValueComponent} from '@forms/primitives/value/percent-value.component';
import {StartCaseValueComponent} from '@forms/primitives/value/start-case-value.component';
import {UpperCaseValueComponent} from '@forms/primitives/value/upper-case-value.component';
import {WarningComponent} from '@forms/primitives/warning/warning.component';
import {fieldDateRangeValidator, fieldMatchValidator, minlengthValidationMessages} from '@forms/validators';
import {ClickableWrapperComponent} from '@forms/wrappers/clickable-wrapper/clickable-wrapper.component';
import {StyleWrapperComponent} from '@forms/wrappers/style-wrapper/style-wrapper.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import {FormlyModule} from '@ngx-formly/core';
import {FormlySelectModule} from '@ngx-formly/core/select';
import * as moment from 'moment';
import {NouisliderModule} from 'ng2-nouislider';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ArrayComponent} from './primitives/array/array.component';
import {CoordinatesComponent} from './primitives/coordinates/coordinates.component';
import {ImageComponent} from './primitives/image/image.component';
import {NullComponent} from './primitives/null/null.component';
import {ObjectComponent} from './primitives/object/object.component';
import {TextAreaComponent} from './primitives/text-area/text-area.component';
import {TypeheadComponent} from './primitives/typehead/typehead.component';
import {CardWrapperComponent} from './wrappers/card-wrapper/card-wrapper.component';

export function toDateParser(date: Date) {
  return date ? moment(date).format('YYYY-MM-DD') : null;
}

@NgModule({
  declarations: [
    ObjectComponent,
    ArrayComponent,
    NullComponent,
    CoordinatesComponent,
    ImageComponent,
    TextAreaComponent,
    CardWrapperComponent,
    TypeheadComponent,
    StyleWrapperComponent,
    TickComponent,
    IconValueComponent,
    LinkValueComponent,
    PercentValueComponent,
    LabelledLinkValueComponent,
    MoneyValueComponent,
    StartCaseValueComponent,
    UpperCaseValueComponent,
    MappedValueComponent,
    ValueComponent,
    WarningComponent,
    SliderFormComponent,
    DatepickerComponent
  ],
  providers: [],
  imports: [
    SharedModule,
    CommonModule,
    DragDropModule,
    AngularFormsModule,
    ReactiveFormsModule,
    LeafletModule,
    RouterModule,
    SharedModule,
    FormlyModule.forRoot({
      extras: {checkExpressionOn: 'modelChange'},
      validators: [
        {name: 'fieldMatch', validation: fieldMatchValidator},
        {name: 'dateRange', validation: fieldDateRangeValidator},
      ],
      validationMessages: [
        {name: 'required', message: 'This field is required'},
        {name: 'minLength', message: minlengthValidationMessages},
        {name: 'min', message: 'This field exceeds the minimum'},
        {name: 'max', message: 'This field exceeds the maximum'}
      ],
      wrappers: [
        {name: 'card', component: CardWrapperComponent},
        {name: 'clickable', component: ClickableWrapperComponent},
        {name: 'style', component: StyleWrapperComponent},
      ],
      extensions: [],
      types: [
        {name: 'string', extends: 'input'},

        {name: 'date', component: DatepickerComponent},
        {name: 'textarea', component: TextAreaComponent},
        {name: 'value', component: ValueComponent},
        {name: 'money-value', component: MoneyValueComponent},
        {name: 'link-value', component: LinkValueComponent},
        {name: 'percent-value', component: PercentValueComponent},
        {name: 'labelled-link', component: LabelledLinkValueComponent},
        {name: 'icon-value', component: IconValueComponent},
        {name: 'start-case-value', component: StartCaseValueComponent},
        {name: 'upper-case-value', component: UpperCaseValueComponent},
        {name: 'mapped-value', component: MappedValueComponent},
        {name: 'warning', component: WarningComponent},
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            className: 'expand',
            templateOptions: {
              type: 'number',
            },
          },
        },
        {name: 'boolean', extends: 'checkbox'},
        {name: 'enum', extends: 'select'},
        {name: 'slider', component: SliderFormComponent},
        {name: 'null', component: NullComponent, wrappers: ['form-field']},
        {name: 'array', component: ArrayComponent},
        {name: 'object', component: ObjectComponent},
        {name: 'image', component: ImageComponent},
        {name: 'tick', component: TickComponent},
        {name: 'typehead', component: TypeheadComponent},
        {
          name: 'coordinates',
          wrappers: ['form-field'],
          component: CoordinatesComponent,
        },
      ]
    }),
    FormlySelectModule,
    FormlyBootstrapModule,
    NgSelectModule,
    PerfectScrollbarModule,
    NouisliderModule,
    BsDatepickerModule
  ],
  exports: [
    ReactiveFormsModule,
    FormlyBootstrapModule,
    FormlyModule]
})
export class FormsModule {
}
