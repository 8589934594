<div class="pt-1">
  <p class="mb-0" *ngIf="to.description">{{ to.description }}</p>

  <div *ngFor="let field of field.fieldGroup; let i = index;">

    <div class="row pt-2">
      <formly-field [field]="field" class="col"></formly-field>

      <div class="col-1 text-left d-flex align-items-center" *ngIf="!to.disabled">
        <div>
          <a (click)="remove(i)" class="text-danger"><i class='fa fa-trash-alt'></i></a>
        </div>
      </div>

    </div>
    <div class="row m-2 status-incorrect"
      *ngFor="let err of field.formControl.errors | keyvalue"> {{ err.value.message  }}</div>

    <div class="separator"></div>

  </div>

  <div class="d-flex flex-row mt-4">
    <div class="col-10">
      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </div>
    <div class="col-2 text-right">
      <button class="btn btn-primary" type="button" (click)="add()" *ngIf="!to.disabled">
        <i class='fa fa-plus'></i>
      </button>
    </div>
  </div>

</div>
