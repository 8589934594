import {AfterViewInit, Component, OnInit, Renderer2} from '@angular/core';
import {LangService} from '@app/shared/lang.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = false;

  constructor(private langService: LangService, private renderer: Renderer2) {

  }

  ngOnInit() {
    this.langService.init();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }
}
