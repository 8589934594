import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

/**
 * Directive that colours the html element based on the polarity of the given number
 */
@Directive({
  selector: '[appColoredNumber]'
})
export class ColoredNumberDirective implements OnInit {

  @Input('appColoredNumber') amount: number;

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {
  }

  ngOnInit() {
    const nativeElement: HTMLSpanElement = this.elementRef.nativeElement;

    if (this.amount) {
      if (this.amount > 0) {
        this.renderer.addClass(nativeElement, 'text-success');
      } else if (this.amount < 0) {
        this.renderer.addClass(nativeElement, 'text-danger');
      }
    }
  }

}
