import {ChangeDetectorRef, Component} from '@angular/core';
import {FieldArrayType} from '@ngx-formly/core';

@Component({
  selector: 'formly-array-type',
  templateUrl: './array.component.html',
  styleUrls: ['./array.component.scss']
})
export class ArrayComponent extends FieldArrayType {

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  add(): void {
    super.add();
    this.changeDetectorRef.detectChanges();
  }

}
