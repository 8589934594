import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ContractSummary} from '@api/model/contractSummary';
import {ChangePasswordModalComponent} from '@app/containers/layout/topnav/change-password/change-password-modal.component';
import {ContractDetailsModalComponent} from '@app/containers/layout/topnav/contract-details-modal/contract-details-modal.component';
import {ActiveContractService} from '@app/shared/active-contract.service';
import {AuthService} from '@app/shared/auth.service';
import {NotificationsService} from 'angular2-notifications';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {first, switchMap, tap} from 'rxjs/operators';
import {LangService, Language} from 'src/app/shared/lang.service';
import {getThemeColor, setThemeColor} from 'src/app/utils/util';
import {environment} from 'src/environments/environment';
import {ISidebar, SidebarService} from '../sidebar/sidebar.service';

interface IIconMenuItem {
  icon: string;
  title: string;
  click: () => void;
}

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy {
  appRoot = environment.appRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'N/A';
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  userAvatar: string;

  iconMenus: IIconMenuItem[] = [];

  activeContract: ContractSummary = undefined;
  contracts: ContractSummary[] = [];

  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private activeContractService: ActiveContractService,
    private router: Router,
    private langService: LangService,
    private notifications: NotificationsService,
    private modalService: BsModalService
  ) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1;
  }

  onDarkModeChange() {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    this.isDarkModeActive = !this.isDarkModeActive;
  }

  fullScreenClick() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange')
  handleFullscreen() {
    this.isFullScreen = !!document.fullscreenElement;
  }

  onLanguageChange(lang) {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  async ngOnInit() {
    this.authService.getUser().pipe(first()).subscribe((user) => {
      this.displayName = user.username;
      this.userAvatar = user.profile_picture_url;
    });
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );

    this.getActiveContract();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  };

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  };

  getActiveContract() {
    this.activeContractService.getActiveContract()
      .pipe(
        tap(activeContract => this.activeContract = activeContract),
        switchMap(() => this.activeContractService.getContracts())
      ).subscribe(contracts => this.contracts = contracts);
  }

  onActiveContractChange(contract: ContractSummary) {
    this.activeContract = contract;
    this.activeContractService.storeActiveContract(this.activeContract);
    this.reloadPage();
  }

  reloadPage() {
    const currentUrl = this.router.url;
    this.router.navigate(['/user/login'], {skipLocationChange: true, queryParamsHandling: 'merge'})
      .then(() => this.router.navigateByUrl(currentUrl, {replaceUrl: true}));
  }

  onMyContractDetails() {
    this.modalService.show(ContractDetailsModalComponent, {
      class: 'modal-md modal-dialog-centered'
    });
  }

  onMyDetails() {
    this.router.navigate([this.appRoot, 'landowner']);
  }

  onChangePassword() {
    this.modalService.show(ChangePasswordModalComponent, {
      initialState: {
        list: [() => this.notifications.info('Successful', 'Password was changed successfully')]
      },
      class: 'modal-md modal-dialog-centered'
    });
  }

  onSignOut() {
    this.authService.signOut();
    this.router.navigate(['/user/login']);
  }

  searchKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  searchAreaClick(event) {
    event.stopPropagation();
  }

  searchClick(event) {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search() {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.appRoot + '/#'], {
        queryParams: {key: this.searchKey.toLowerCase().trim()},
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click')
  handleDocumentClick() {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }
}
